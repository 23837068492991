import { convertHex } from '../../helpers/functions/functions';

const libraries = (theme) => {
	return {
		'.libraries-tree-item-icon': {
			fontSize: '30px !important',
			width: theme.spacing(4),
		},
		'.libraries-management-tree-item-icon': {
			fontSize: '30px !important',
			width: theme.spacing(4),
		},
		'.libraries-tree-item-icon-svg': {
			fontSize: 'inherit !important',
		},
		'.libraries-tree-item-content-folder': {
			height: 'auto',
			border: '2px solid ' + theme.palette.neutral.main,
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
			borderRadius: theme.spacing(1),
			'&:hover': {
				backgroundColor: theme.palette.primary.main + ' !important',
				color: theme.palette.primary.contrastText + ' !important',
			},
			'&:hover .MuiTypography-root': {
				color: theme.palette.primary.contrastText + ' !important',
			},
		},
		'.libraries-management-tree-item-content-folder': {
			height: 'auto',
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
			borderRadius: theme.spacing(0),
			border: '2px solid ' + theme.palette.neutral.main,
		},
		'.libraries-management-tree-drive-item-content-folder': {
			height: 'auto',
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
			borderRadius: theme.spacing(0),
			border: '2px solid ' + theme.palette.neutral.main,
		},
		'.libraries-tree-item-files-label': {
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(0.5, 0),
		},
		'.libraries-tree-item-files-label-text': {
			flexGrow: 1,
		},
		'.libraries-tree-item-files-label-text-code-error': {
			flexGrow: 1,
			textDecoration: 'line-through',
		},
		'.libraries-tree-item-files-edit-icon .MuiTreeItem-iconContainer': {
			width: '36px',
			marginLeft: '-20px',
		},
		'.libraries-tree-item-files-label-chip': {
			borderRadius: theme.spacing(0.5),
			maxWidth: '-webkit-fill-available',
		},
		'.libraries-tree-item-files-label-spacing': {
			width: '10%',
		},
		'.libraries-tree-item-selected': {
			backgroundColor: theme.palette.primary.light + ' !important',
			borderColor: theme.palette.primary.main + ' !important',
			color: theme.palette.primary.contrastText + ' !important',
			'& .MuiTypography-root': {
				color: theme.palette.primary.contrastText + ' !important',
			},
		},
		'.libraries-folder-form-container': {
			borderRadius: theme.spacing(0.5),
			marginTop: theme.spacing(1),
		},
		'.libraries-folder-form-button': {
			margin: theme.spacing(2, 0, 2),
		},
		'.libraries-drafts-list': {
			marginTop: theme.spacing(2),
		},
	};
};

export default libraries;
