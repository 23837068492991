import React from 'react';
import { lazyRetry } from '../../../helpers/functions/lazy_loading';

const FilesForPatients = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./FilesForPatients'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'mypages-files-patients')
);
const MyProfile = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./MyProfile'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'mypages-myprofile')
);
const Password = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./ChangePassword/Password'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'mypages-change-password')
);
const MyTexts = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./MyTexts'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'my-pages-mytexts')
);
const MyCalendar = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./Calendar/MyCalendar'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'mypages-mycalendar')
);
const PrivacyPolicy = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./PrivacyPolicy'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'mypages-privacy-policy')
);
const Appointments = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./Appointments'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'mypages-appointments')
);
const MyDashboard = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./MyDashboard'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'mypages-mydashboard')
);

export { FilesForPatients, MyProfile, MyTexts, MyCalendar, PrivacyPolicy, Password, Appointments, MyDashboard };
