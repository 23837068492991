import {
	FETCH_TICKETS_DATA,
	FETCH_TICKET,
	UPDATE_TICKET,
	CHANGE_OVERVIEW_COMPONENT,
	TICKET_PAYMENT,
	POST_TICKET,
	FETCH_CARE_TEAM,
	TICKET_DIAGNOSIS,
	SAVE_DIAGNOSIS,
	FETCH_UNREAD_TICKETS_DATA,
	FETCH_HISTORY_RECORD,
	SET_UNREAD_TICKET_DATA,
	CLEAR_TICKET_DATA,
	CLEAR_TICKETS_LIST,
	FETCH_TICKET_SURVEYS,
	SET_READ_REF_TICKET,
	SET_READ_REF_ITEM,
} from '../actions/types';

import { CLEAR_REDUCER } from '../../Login/actions/types';

import { PATCH_QUESTIONNAIRE } from '../../Questionnaires/actions/types';

const initialState = {
	fields: [],
	data: [],
	total_count: null,
	ticket: false,
	new: '',
	overview_component: '',
	overview_component_id: '',
	overview_component_back: '',
	questionnaires: {
		previous_ticket_questionnaire: '',
		next_questionnaire: '',
		next_ticket_questionnaire: '',
	},
	careteam: [],
	history: [],
	ticket_diagnosis: [],
	checked_diagnosis: [],
	save_diagnosis: [],
	unread: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_TICKETS_DATA:
			return {
				...state,
				fields: action.payload.columns,
				data: action.payload.data,
				total_count: action.payload.total_count,
			};
		case FETCH_TICKET:
			return {
				...initialState,
				//overview_component: state.overview_component,
				//overview_component_id: state.overview_component_id,
				//overview_component_back: state.overview_component_back,
				ticket: action.payload,
				fields: state.fields,
				data: state.data,
				total_count: state.total_count,
				questionnaires: {
					previous_ticket_questionnaire: '',
					next_questionnaire: action.payload.questionnaires.length > 0 ? action.payload.questionnaires[0].questionnaires_id : '',
					next_ticket_questionnaire: action.payload.questionnaires.length > 0 ? action.payload.questionnaires[0].id : '',
				},
			};
		case UPDATE_TICKET:
			return {
				...state,
				ticket: action.payload,
			};
		case POST_TICKET:
			return {
				...state,
				new: action.payload,
			};
		case PATCH_QUESTIONNAIRE:
			return {
				...state,
				ticket: {
					...state.ticket,
					questionnaires: state.ticket.questionnaires.map((questionnaire) =>
						questionnaire.id === action.payload.current_ticket_questionnaire
							? { ...questionnaire, triage_result: action.payload.triage_result }
							: questionnaire
					),
				},
				questionnaires: {
					previous_ticket_questionnaire: action.payload.current_ticket_questionnaire,
					next_questionnaire: action.payload.next_questionnaire,
					next_ticket_questionnaire: action.payload.next_ticket_questionnaire,
				},
			};
		case CHANGE_OVERVIEW_COMPONENT:
			return {
				...state,
				overview_component: action.payload.overview_component,
				overview_component_id: action.payload.overview_component_id,
				overview_component_back: action.payload.overview_component_back,
			};
		case TICKET_PAYMENT:
			return {
				...state,
				ticket: action.payload,
			};
		case FETCH_CARE_TEAM:
			return {
				...state,
				careteam: action.payload,
			};
		case FETCH_TICKET_SURVEYS:
			return {
				...state,
				ticket: {
					...state.ticket,
					surveys: action.payload.items,
				},
			};
		case TICKET_DIAGNOSIS:
			return {
				...state,
				ticket_diagnosis: action.payload,
			};
		case SAVE_DIAGNOSIS:
			return {
				...state,
				save_diagnosis: action.payload,
			};
		case FETCH_UNREAD_TICKETS_DATA:
			return {
				...state,
				unread: action.payload,
			};
		case SET_UNREAD_TICKET_DATA:
			return {
				...state,
				ticket: {
					...state.ticket,
					unread: action.payload,
				},
			};
		case SET_READ_REF_TICKET:
			if (action.payload.ref_type) {
				return {
					...state,
					ticket: {
						...state.ticket,
						[action.payload.ref_type]: {
							...state.ticket[action.payload.ref_type],
							unreads: 0,
						},
					},
				};
			} else {
				return {
					...state,
					ticket: {
						...state.ticket,
						unread: [],
					},
				};
			}
		case SET_READ_REF_ITEM:
			let aux_items = state.ticket[action.payload.ref_type].items.map((item) => {
				if (item.id == action.payload.ref_id) item.unread = 0;
				return item;
			});

			return {
				...state,
				ticket: {
					...state.ticket,
					[action.payload.ref_type]: {
						...state.ticket[action.payload.ref_type],
						items: aux_items,
					},
				},
			};
		case FETCH_HISTORY_RECORD:
			return {
				...state,
				history: state.history.concat(action.payload),
			};
		case CLEAR_TICKET_DATA:
			return {
				...state,
				ticket: false,
			};
		case CLEAR_TICKETS_LIST:
			return {
				...state,
				data: [],
			};
		case CLEAR_REDUCER:
			return {
				...initialState,
			};
		default:
			return state;
	}
}
