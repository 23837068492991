import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setChannelAction,
	subscribeChannelAction,
	unsubscribeChannelAction,
	setChannelHistoryAction,
	publishChannelAction,
	setWsChannelHistoryAction,
	sendChannelMessageAction,
	// clearChannelAction,
	setChannelParticipantsAction,
	setChannelNewMessagesAction,
	setReadTicketNotificationsAction,
	disconnectAction,
	participantSubscribedAction,
	participantUnsubscribedAction,
	modifyChannelAction,
	setWebsocketToken,
	getChannelMessagesAction,
	setSubscribingChannel,
	setGettingChannelMessages,
	setUnreadCount,
} from './actions/websocketsActions';
import { fetchTicketAction, setTicketRefReadAction, setTicketUnreadAction } from '../Tickets/actions/ticketsActions';
import soundfile from '../../assets/sounds/mention-alert.mp3';
import { handleBlockingUIAction, handlePersistentAlert } from '../Generic/actions/genericActions';
import eventsService from '../../helpers/Events';
import MESSAGES from '../../helpers/messages';
import { getTranslate } from 'react-localize-redux';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { dateToString, parseDateOffset } from '../../helpers/functions/functions';
import { NOTIFICATIONS } from '../../helpers/tables';
import { setTableCountAction, changeTablePageAction, setTableLoadinAction } from '../Generic/actions/tableActions';

export const SocketContext = React.createContext();

/** Connects to a websocket channel on ws://<host>/ws
 * Attempts to get a short lived token from the api to use as authentication
 * when connecting to the websocket channel and then proceeds try to connect.
 *
 * Tokens are very short lived so a new one should be fetched if you need to
 * reconnect.
 */
class SocketProvider extends Component {
	constructor() {
		super();
		this.state = {
			ws: null,
			token: null,
			attempts: 5,
			subscribing: [],
			unsubscribing: [],
			close_code: null,
			retry_connect: true,
		};

		this.audio = new Audio(soundfile);
	}

	componentDidMount() {
		if (this.props.token) {
			this.connect();
		}
	}

	componentDidUpdate(prevProps) {
		if (!this.state.ws && prevProps.token === null && this.props.token) {
			this.connect();
		} else if (this.state.ws && (!this.props.token || (prevProps.login && !this.props.login))) {
			this.state.ws.close(4005);
		}
	}

	componentWillUnmount() {
		if (this.state.ws !== null) {
			this.state.ws.close(4005);
		}
	}

	timeout = 250; // Initial timeout duration as a class variable

	recconectChannels = () => {
		if (this.props.subscribed_channels.length > 0) {
			// this.props.handleBlockingUIAction(true, 'block_ui_reconnecting');
			this.props.subscribed_channels.forEach((channel, index) => {
				if (!this.props.websockets.channels?.[channel]) {
					setTimeout(() => {
						this.subscribe(channel);
						if (this.props.subscribed_channels.length === index + 1) {
							this.props.handleBlockingUIAction(false, 'block_ui_reconnecting');
						}
					}, 500);
				}
			});
		}
	};

	/**
	 * @function connect
	 * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
	 */
	connect = () => {
		var ws = new WebSocket(this.props.config.websocket_protocol + `://` + this.props.config.websockethost + '?token=' + this.props.token);

		if (new Date(this.props.token_expires) <= new Date(new Date().toLocaleString('en-US', { timeZone: this.props.timezone }))) {
			this.props.setWebsocketToken();
		}

		// let that = this; // cache the this
		var connectInterval;
		// websocket onopen event listener
		ws.onopen = () => {
			setTimeout(() => {
				if (ws.readyState === WebSocket.OPEN) {
					var connection_date = new Date();
					this.setState(
						{
							connection_date,
							attempts: 5,
							ws: ws,
						},
						() => {
							if (this.props.subscribed_channels.length > 0) {
								this.recconectChannels();
							} else {
								this.props.handleBlockingUIAction(false, 'block_ui_reconnecting');
							}
						}
					);

					this.timeout = 250; // reset timer to 250 on open of websocket connection
					clearTimeout(connectInterval); // clear Interval on on open of websocket connection
				}
			}, 1000);
		};

		// websocket onclose event listener
		ws.onclose = (e) => {
			this.setState(
				{
					ws: null,
					close_code: e.code,
				},
				() => {
					if (e.code !== 4005) {
						if (this.state.attempts > 0) {
							this.props.handleBlockingUIAction(true, 'block_ui_reconnecting');
							this.props.disconnectAction(true);
							setTimeout(() => {
								this.setState(
									{
										attempts: this.state.attempts - 1,
									},
									() => {
										setTimeout(() => {
											if (!this.state.ws) {
												this.connect();
											}
										}, 1000);
									}
								);
							}, 500);
						} else {
							eventsService.triggerEvent('alert', {
								type: 'error',
								message: this.props.translate('websocket_failed_connection_alert_message'),
							});
							this.props.handlePersistentAlert({
								open: true,
								message: this.props.translate('websocket_failed_connection_persistent_message'),
								severity: 'warning',
								action: (
									<IconButton
										aria-label="refresh"
										color="inherit"
										size="small"
										onClick={() => {
											window.location.reload();
										}}
									>
										<RefreshIcon />
									</IconButton>
								),
							});
							this.props.handleBlockingUIAction(false, 'block_ui_reconnecting');
						}
					} else {
						this.setState(
							{
								attempts: 5,
							},
							() => {
								if (this.props.blocking_ui.open) {
									this.props.handleBlockingUIAction(false, 'block_ui_reconnecting');
								}
								this.props.disconnectAction(false);
							}
						);
					}
				}
			);

			if (e.code !== 4005) {
				this.timeout = this.timeout + this.timeout; //increment retry interval
				connectInterval = setTimeout(this.check, Math.min(10000, this.timeout)); //call check function after timeout
			}
		};

		// websocket onerror event listener
		ws.onerror = (err) => {
			ws.close();
		};

		ws.onmessage = (msg) => {
			var data = JSON.parse(msg.data);

			if (data.type === 'subscribe' && data.sender.id == this.props.user.id) {
				this.props.subscribeChannelAction(
					data.channel,
					data.created,
					// moment(data.created).format('YYYY-MM-DD HH:mm:ss'),
					// data.history,
					data.participants,
					data.ref_type,
					data.ref_id,
					data.title,
					data.unread_count,
					(channel) => {
						setTimeout(() => {
							if (channel === this.props.user.channel.name) {
								this.props.setTableLoadinAction(NOTIFICATIONS, true);
								this.props.changeTablePageAction(NOTIFICATIONS, 0);
							}
							this.setSubscribed(channel);
							var before_dt =
								parseDateOffset(new Date(new Date().toLocaleString('en-US', { timeZone: this.props.timezone })))
									.toISOString()
									.split('.')[0] + 'Z';
							before_dt = before_dt.replace('T', ' ').replace('Z', '');
							this.getMessages(
								channel,
								{
									// TODO: add this check to whenever getting messages
									cnt: channel === this.props.user.channel.name ? this.props.tables_settings[NOTIFICATIONS]?.rows_per_page[0] : 20,
									before_dt,
								},
								'replace'
							);
						}, 1000);
					}
				);
				// TODO: call getMessages instead of using history from subscribe message
			} else if (data.type === 'subscribe' && data.sender.id != this.props.user.id) {
				this.props.participantSubscribedAction(data.channel, data.sender);
			} else if (data.type === 'unsubscribe' && data.sender.id == this.props.user.id) {
				this.props.unsubscribeChannelAction(data.channel, this.setUnsubscribed);
			} else if (data.type === 'unsubscribe' && data.sender.id != this.props.user.id) {
				this.props.participantUnsubscribedAction(data.channel, data.sender);
			}

			// TODO: We need to define if readmark should a type of message or not (api listenner assume the readmark type)
			// if (data.type === 'readmark') {
			// 	data.type = 'publish';
			// 	data.link_type = 'readmark';
			// }

			if (data.type === 'publish' || data.type === 'notification') {
				// Populate information of unread and ref in order to use markread functionality
				if (typeof data.unread === 'undefined') {
					data.unread = data.sender.id != this.props.user.id ? '1' : '0';
				}
				if (typeof data.ref_type === 'undefined') {
					data.ref_type = MESSAGES.REF.CHAT_MESSAGES;
					data.ref_id = data.id;
				}

				this.props.publishChannelAction(data);

				if (this.props.websockets.channels[data.channel].callback) {
					this.props.websockets.channels[data.channel].callback();
				}
			}

			if (data.type === 'modifymessage') {
				this.props.modifyChannelAction(data);
			}

			if (data.type === 'getmessages') {
				this.props.getChannelMessagesAction(data.channel, data.messages, (messages) => {
					if (data.channel === this.props.user.channel.name) {
						this.props.setTableLoadinAction(NOTIFICATIONS, false);
						this.props.setTableCountAction(NOTIFICATIONS, data.messages.total);
					}
				});
			}

			// To be improved given that in some situations there no need to update the ticket info (ex: patient on chat layout)
			if (this.props.websockets.channels?.[data.channel]) {
				if (this.props.websockets.channels?.[data.channel].ref_type === MESSAGES.ORIG.TICKETS) {
					if ((data.type == 'publish' || data.type == 'modifymessage') && data.link_type != 'readmark') {
						this.props.fetchTicketAction(this.props.websockets.channels?.[data.channel].ref_id, false);
					} else if (data.link_type == 'readmark') {
						// Update Unreads list
						// TODO: fix this trigger, request unread_count on ws control messages
						let unreads = this.props.websockets.channels[data.channel].messages.items.filter((message) => {
							return message.unread == '1';
						});
						unreads = unreads.map((item) => ({ id: item?.id, ref_type: item?.ref_type, ref_id: item?.ref_id }));
						this.props.setTicketUnreadAction(unreads);
					}
				}
			}

			if (data.type === 'control' && data.link_type === 'readmark') {
				this.props.setUnreadCount(data.channel, 'subtract');

				switch (data.origin_type) {
					case MESSAGES.ORIG.TICKETS:
					if (data.ref_type) {
            this.props.setTicketRefReadAction(data.ref_type);
          }	
						break;

					default:
						break;
				}
			}

			if (data.type !== 'control' && data.unread == 1) {
				console.log('New unread message.');
				this.props.setUnreadCount(data.channel, 'add');
			}

			// if (this.props.handleFilterMessages) {
			// 	if (this.props.handleFilterMessages(data)) {
			// 		// this.props.sendChannelMessageAction(this.props.channel_name, data, this.props.pushMessageToTop);
			//     console.debug(data)
			//     this.props.publishChannelAction(data);
			// 	}
			// 	if (data.type === 'readmark' || data.type === 'downloadmark') {
			// 		this.props.setReadTicketNotificationsAction(
			// 			this.props.channel_name,
			// 			data.origin_type,
			// 			data.origin_id,
			// 			data.ref_type,
			// 			data.ref_id
			// 		);
			// 	}
			// } else {
			//   this.props.publishChannelAction(data);
			// 	// this.props.sendChannelMessageAction(this.props.channel_name, data, this.props.pushMessageToTop);
			// 	if (data.type === 'readmark' || data.type === 'downloadmark') {
			// 		this.props.setReadTicketNotificationsAction(
			// 			this.props.channel_name,
			// 			data.origin_type,
			// 			data.origin_id,
			// 			data.ref_type,
			// 			data.ref_id
			// 		);
			// 	}
			// }
		};

		// ws.onmessage = (msg) => {
		// 	console.debug('message');
		// 	var data = JSON.parse(msg.data);

		// 	if (data.type === 'subscribe') {
		// 		this.props.subscribeChannelAction(data.channel, data.created);
		// 	}

		// 	if (data.type === 'unsubscribe') {
		// 		this.props.unsubscribeChannelAction(data.channel, data.created);
		// 	}

		// 	if (data.type === 'welcome') {
		// 		this.props.setWsChannelHistoryAction(data.channel, data.history, data.participants);
		// 	}

		// 	if (data.type === 'publish') {
		// 		this.props.publishChannelAction(data.channel, data.message, data.created, data.sender);

		//     if (this.props.websockets[data.channel].callback) {
		//       this.props.websockets[data.channel].callback();
		//     }
		// 	}

		// 	if (data.created) {
		// 		data.created = moment(data.created).format('YYYY-MM-DD HH:mm:ss');
		// 	}

		// 	if (data.type === 'history' && !this.props.skip_history) {
		// 		if (data.message && data.message.length > 0) {
		// 			data.message.forEach((message) => {
		// 				if (message.created) {
		// 					message.created = moment(message.created).format('YYYY-MM-DD HH:mm:ss');
		// 				}
		// 			});
		// 		}
		// 		if (this.props.handleFilterMessages && Array.isArray(data.message)) {
		// 			data.message = data.message.filter(this.props.handleFilterMessages);
		// 		}
		// 		this.props.setChannelHistoryAction(this.props.channel_name, data.message);
		// 	} else if (data.type === 'participants') {
		// 		this.props.setChannelParticipantsAction(this.props.channel_name, data.message);
		// 	} else if (!this.props.skip_history) {
		// 		if (this.props.handleFilterMessages) {
		// 			if (this.props.handleFilterMessages(data)) {
		// 				this.props.sendChannelMessageAction(this.props.channel_name, data, this.props.pushMessageToTop);
		// 			}
		// 			if (data.type === 'readmark' || data.type === 'downloadmark') {
		// 				this.props.setReadTicketNotificationsAction(
		// 					this.props.channel_name,
		// 					data.origin_type,
		// 					data.origin_id,
		// 					data.ref_type,
		// 					data.ref_id
		// 				);
		// 			}
		// 		} else {
		// 			this.props.sendChannelMessageAction(this.props.channel_name, data, this.props.pushMessageToTop);
		// 			if (data.type === 'readmark' || data.type === 'downloadmark') {
		// 				this.props.setReadTicketNotificationsAction(
		// 					this.props.channel_name,
		// 					data.origin_type,
		// 					data.origin_id,
		// 					data.ref_type,
		// 					data.ref_id
		// 				);
		// 			}
		// 		}
		// 		if (
		// 			this.state.connection_date !== null &&
		// 			data.automatic === '0' &&
		// 			data.sender_id !== this.props.user.id &&
		// 			typeof data.created !== 'undefined' &&
		// 			parseDateUTC(data.created) > this.state.connection_date
		// 		) {
		// 			if (
		// 				this.props.mentions &&
		// 				data.users_mentions &&
		// 				Array.isArray(data.users_mentions) &&
		// 				data.users_mentions.includes(this.props.user.id)
		// 			) {
		// 				this.props.setChannelNewMessagesAction(this.props.channel_name, true);
		// 				this.audio.play();
		// 			} else {
		// 				this.props.setChannelNewMessagesAction(this.props.channel_name, false);
		// 			}
		// 		}
		// 		// if (data) {
		// 		// 	data.message.includes(`<span id="chat-user-mention" data-id="${this.props.user.id}">`)
		// 		// }
		// 		if (this.props.handleSendMessage) this.props.handleSendMessage(data);
		// 	}
		// };
	};

	/**
	 * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
	 */
	check = () => {
		const { ws } = this.state;
		// if (!ws || ws.readyState === WebSocket.CLOSED) {console.debug('re connect?'); this.preconnect();} //check if websocket instance is closed, if so call `connect` function.
	};

	setSubscribed = (channel_name) => {
		this.setState({
			subscribing: this.state.subscribing.filter((name) => name !== channel_name),
		});
	};

	setUnsubscribed = (channel_name) => {
		this.setState({
			unsubscribing: this.state.unsubscribing.filter((name) => name !== channel_name),
		});
	};

	subscribe = (channel = null) => {
		const { ws } = this.state;

		this.props.setSubscribingChannel(channel);

		setTimeout(() => {
			if (!this.state.subscribing.includes(channel) && !this.props.websockets.channels[channel]) {
				this.setState(
					{
						subscribing: [...this.state.subscribing, channel],
					},
					() => {
						ws.send(
							JSON.stringify({
								type: 'subscribe',
								channel,
							})
						);
					}
				);
			}
		}, 1000);
	};

	getMessages = (channel = null, params = {}, position = 'before') => {
		const { ws } = this.state;

		if (channel === this.props.user.channel.name) {
			this.props.setTableLoadinAction(NOTIFICATIONS, true);
		}

		this.props.setGettingChannelMessages(channel, position);

		setTimeout(() => {
			ws.send(
				JSON.stringify({
					type: 'getmessages',
					channel,
					...params,
					// cnt: 1,
					// after_cnt: 4
				})
			);
		}, 500);
	};

	unsubscribe = (channel = null) => {
		const { ws } = this.state;

		if (!this.state.unsubscribing.includes(channel) && this.props.websockets.channels[channel]) {
			this.setState(
				{
					unsubscribing: [...this.state.unsubscribing, channel],
				},
				() => {
					ws.send(
						JSON.stringify({
							type: 'unsubscribe',
							channel,
						})
					);
				}
			);
		}
	};

	publish = (channel = null, data, callback = false) => {
		const { ws } = this.state;

		ws.send(
			JSON.stringify({
				type: 'publish',
				channel,
				...data,
			})
		);

		if (callback) {
			callback();
		}
	};

	modify = (channel = null, message_id, text = '') => {
		const { ws } = this.state;

		ws.send(
			JSON.stringify({
				type: 'modifymessage',
				channel,
				message_id,
				text,
			})
		);
	};

	render() {
		return (
			<SocketContext.Provider
				value={{
					ws: this.state.ws,
					subscribe: this.subscribe,
					unsubscribe: this.unsubscribe,
					publish: this.publish,
					modify: this.modify,
					getMessages: this.getMessages,
				}}
			>
				{this.props.children}
			</SocketContext.Provider>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	translate: getTranslate(state.localize),
	user: state.users.whoami,
	config: state.config,
	timezone: state.users.whoami.timezone,
	websockets: state.websockets,
	token_expires: state.websockets.token_expires,
	token: state.websockets.token,
	login: state.login.login,
	subscribed_channels: state.websockets.subscribed_channels,
	blocking_ui: state.generic.blocking_ui,
	tables_settings: state.settings?.site?.tables,
});

export default connect(mapStateToProps, {
	setChannelAction,
	subscribeChannelAction,
	unsubscribeChannelAction,
	publishChannelAction,
	setChannelHistoryAction,
	setWsChannelHistoryAction,
	sendChannelMessageAction,
	fetchTicketAction,
	setTicketUnreadAction,
	setChannelParticipantsAction,
	setChannelNewMessagesAction,
	setReadTicketNotificationsAction,
	disconnectAction,
	participantSubscribedAction,
	participantUnsubscribedAction,
	modifyChannelAction,
	handleBlockingUIAction,
	setWebsocketToken,
	handlePersistentAlert,
	getChannelMessagesAction,
	setSubscribingChannel,
	setGettingChannelMessages,
	setTableCountAction,
	changeTablePageAction,
	setTableLoadinAction,
	setUnreadCount,
	setTicketRefReadAction,
})(SocketProvider);

SocketProvider.propTypes = {
	channel_name: PropTypes.string,
	channel: PropTypes.string,
	handleSendMessage: PropTypes.func,
	customFirstMessage: PropTypes.func,
	handleFilterMessages: PropTypes.func,
	token: PropTypes.string,
	token_expires: PropTypes.string,
};
