import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { Badge, IconButton } from '@mui/material';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import SocketProvider, { SocketContext } from '../Chat/SocketProvider';
import { filterChannelMessagesAction } from '../Chat/actions/websocketsActions';
import { displayNotificationsModalAction } from './actions/genericActions';

class Notifications extends Component {
	static contextType = SocketContext;

	handleFilterMessages = (data) => {
		return data.type === 'publish' && data.link_type !== 'read'; //Check this
	};

	handleSendMessage = (data) => {
		if (data.type === 'publish' && data.unread == '1') {
			var bellList = document.getElementById('listBell_' + data.ticket_id);
			var bellNav = document.getElementById('navBell');

			if (bellList) {
				bellList.classList.remove('notification-bell-hidden');
				bellList.classList.add('notify');
				setTimeout(() => {
					bellList.classList.remove('notify');
				}, 500);
			}
			if (bellNav) {
				bellNav.classList.add('notify');
				setTimeout(() => {
					bellNav.classList.add('notify');
				}, 500);
			}
		} else if ((data.link_type === 'readmark' || data.link_type === 'downloadmark') && data.ticket_id) {
			var bellList = document.getElementById('listBell_' + data.ticket_id);

			if (bellList !== null) {
				bellList.classList.add('notification-bell-hidden');
			}
		}
	};

	render() {
		return (
			<React.Fragment>
				<IconButton
					size={'small'}
					color="inherit"
					aria-controls={this.props.notificationsId}
					aria-haspopup="true"
					onClick={() => this.props.displayNotificationsModalAction(true)}
					// disabled={this.props.messages.length === 0}
				>
					<Badge badgeContent={this.props.unread_count && this.props.unread_count > 0 ? this.props.unread_count : 0} color="error">
						<NotificationsIcon id={'navBell'} />
					</Badge>
				</IconButton>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	// messages: state.websockets.channels && state.websockets.channels[state.users.whoami.channel.name]
	// 	? state.users.whoami.scopes.includes('lists.cross_client')
	// 		? state.websockets.channels[state.users.whoami.channel.name].messages.items.filter((message) => message.unread == '1')
	// 		: state.websockets.channels[state.users.whoami.channel.name].messages.items.filter(
	// 				(msg) => (msg.ticket_client_id == state.users.whoami.current_client || msg.ticket_client_id == "0") && msg.unread == '1'
	// 		  )
	// 	: [],
	unread_count:
		state.websockets.channels && state.websockets.channels[state.users.whoami.channel.name]
			? state.websockets.channels[state.users.whoami.channel.name].unread_count
			: 0,
	whoami: state.users.whoami,
	config: state.config,
	channel: state.users.whoami.channel,
	translate: getTranslate(state.localize),
	timezone: state.users.whoami.timezone,
});

export default connect(mapStateToProps, { filterChannelMessagesAction, displayNotificationsModalAction })(Notifications);
