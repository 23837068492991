import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Backdrop, CircularProgress, Typography, Box, Stack } from '@mui/material';

class BlockUI extends Component {
	render() {
		return (
			<Backdrop sx={{ color: 'rgb(184, 184, 184)', zIndex: (theme) => theme.zIndex.modal + 1, ...this.props.sx }} open={this.props.open}>
				<Box className="loading-indicator">
					<Stack direction={'row'} spacing={1}>
						{this.props.icon ?? this.props.icon}
						<Typography variant="body1" className="block-ui-typography">
							{this.props.translate(this.props.label)}
						</Typography>
					</Stack>
					<CircularProgress />
				</Box>
			</Backdrop>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	translate: getTranslate(state.localize),
	open: ownProps.open ?? state.generic.blocking_ui.open,
	label: ownProps.label ?? state.generic.blocking_ui.label,
	icon: ownProps.icon ?? state.generic.blocking_ui.icon,
});

export default connect(mapStateToProps, null)(BlockUI);
