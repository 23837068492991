import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Collapse } from '@mui/material';
import { loginAction, handleSelectClientAction } from './actions/loginActions';
import { Form } from '../Generic';
import { push } from 'connected-react-router';

class LoginGuest extends Component {
	state = {
		username: '',
		password: '',
		selectedLanguage: this.props.selectedLanguage,
		recovery: false,
	};

	handleUsername = (e) => {
		this.setState({
			username: e.target.value,
		});
	};
	handlePassword = (e) => {
		this.setState({
			password: e.target.value,
		});
	};

	handleDefaultValues = (config) => {
		let values = {};
		config.fields.filter((field) => typeof field.initialvalue !== 'undefined').map((field, i) => (values[field.name] = field.initialvalue));
		return values;
	};

	handleRecovery = () => {
		this.setState({
			recovery: !this.state.recovery,
		});
	};

	handleConfig = () => {
		const { translate } = this.props;

		const structure = {
			form: 'guest',
			title: '',
			subtitle: '',
			helperText: '',
			button_label: translate('login_guest_button'),
			button_loading_label: translate('login_form_loading_button'),
			fields: [
				{
					id: 'deviceid',
					name: 'deviceid',
					label: '',
					type: 'hidden',
				},
				{
					id: 'language',
					name: 'language',
					label: '',
					type: 'hidden',
					initialvalue: this.props.selectedLanguage,
				},
			],
		};

		let initialValues = this.handleDefaultValues(structure);

		return { structure: structure, initialValues: initialValues };
	};

	handleSubmitButton = (form) => {
		if (typeof this.props.handleSubmitAction !== 'undefined') {
			this.props.handleSubmitAction(form);
		}
	};

	render() {
		const { translate } = this.props;
		const configuration = this.handleConfig();

		return (
			<React.Fragment>
				<Collapse in={!this.state.recovery}>
					<Form
						form={configuration.structure.form}
						formData={configuration.structure}
						initialValues={configuration.initialValues}
						handleSubmit={() => this.handleSubmitButton(configuration.structure.form)}
						actionDisabled={this.props.login.client && this.props.login.client.id === 0}
					/>
				</Collapse>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	login: state.login,
	settings: state.settings,
});

export default connect(mapStateToProps, { push, loginAction, handleSelectClientAction })(LoginGuest);
