import {
	SET_CHANNEL,
	SET_CHANNEL_HISTORY,
	SEND_CHANNEL_MESSAGE,
	FILTER_CHANNEL_MESSAGES,
	CLEAR_CHANNEL,
	SET_CHANNEL_PARTICIPANTS,
	SET_CHANNEL_NEW_MESSAGE,
	SET_READ_NOTIFICATIONS,
	SUBSCRIBE_CHANNEL,
	UNSUBSCRIBE_CHANNEL,
	SET_WS_CHANNEL_HISTORY,
	PUBLISH,
	MODIFY,
	DISCONNECT,
	SET_CHANNEL_CALLBACK,
	SET_CONNECTION_TOKEN,
	PARTICIPANT_SUBSCRIBED,
	PARTICIPANT_UNSUBSCRIBED,
	SET_CHANNELS,
	GET_CHANNEL_MESSAGES,
	SET_SUBSCRIBING_CHANNEL,
	GETTING_CHANNEL_MESSAGES,
	SET_UNREAD_COUNT,
} from '../actions/types';
import { WEB_SOCKET_CHANNELS } from '../../../helpers/webSocketChannels';

const initialState = {
	token: null,
	token_expires: null,
	channels: {},
	subscribing_channels: [],
	available_channels: [],
	subscribed_channels: [],
};

export const messagesInitialState = {
	items: [],
	limit: 10,
	next: null,
	prev: null,
	rows: {
		after: {
			count: null,
			unread: null,
		},
		before: {
			count: null,
			unread: null,
		},
	},
	total: null,
};

const channelInitialState = {
	started: null,
	messages: messagesInitialState,
	connection_date: new Date(),
	participants: [],
	callback: false,
	getting_messages: false,
	unread_count: 0,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_CHANNEL:
			return {
				...state,
				[action.payload.channel_name]: { ...state[action.payload.channel_name], ...action.payload.data },
			};
		case SET_CHANNEL_HISTORY:
			return {
				...state,
				[action.payload.channel_name]: {
					...state[action.payload.channel_name],
					messages: {
						...state[action.payload.channel_name].messages,
						items: [...state[action.payload.channel_name].messages.items, ...action.payload.data.messages.items],
					},
					has_history: true,
				},
			};
		case SEND_CHANNEL_MESSAGE:
			return {
				...state,
				[action.payload.channel_name]: {
					...state[action.payload.channel_name],
					messages: {
						...state[action.payload.channel_name].messages,
						items: action.payload.pushMessageToTop
							? [
									action.payload.message,
									...state[action.payload.channel_name].messages.items.filter(
										(message) => message.id !== action.payload.message.id
									),
							  ]
							: [...state[action.payload.channel_name].messages.items, action.payload.message],
					},
				},
			};
		case FILTER_CHANNEL_MESSAGES:
			// change this!!
			return {
				...state,
				[action.payload.channel_name]: {
					...state[action.payload.channel_name],
					messages: {
						...state[action.payload.channel_name].messages,
						items: state[action.payload.channel_name].messages.items.filter(
							(message) => !action.payload.messages_to_remove.some((message_to_remove) => message.id == message_to_remove.id)
						),
					},
				},
			};
		case SET_READ_NOTIFICATIONS:
			return {
				...state,
				channels: {
					...state.channels,
					[action.payload.channel]: {
						...state[action.payload.channel_name],
						messages: {
							...state[action.payload.channel_name].messages,
							items: state[action.payload.channel_name].messages.map((message) => {
								if (message.origin_type === action.payload.origin_type && message.origin_id === action.payload.origin_id) {
									if (!action.payload.ref_type) {
										message.unread = '0';
										return message;
									}
									if (message.ref_type === action.payload.ref_type) {
										if (!action.payload.ref_id || action.payload.ref_id == '0') {
											message.unread = '0';
											return message;
										}
										if (action.payload.ref_id && message.ref_id === action.payload.ref_id) {
											message.unread = '0';
											return message;
										}
									}
								}
								return message;
							}),
						},
					},
				},
			};
		// case CLEAR_CHANNEL:
		// 	return {
		// 		...state,
		// 		[action.payload.channel_name]: initialState[action.payload.channel_name],
		// 	};
		case SET_CHANNEL_PARTICIPANTS:
			return {
				...state,
				[action.payload.channel_name]: {
					...state[action.payload.channel_name],
					participants: action.payload.participants,
				},
			};
		case SET_CHANNEL_NEW_MESSAGE:
			return {
				...state,
				[action.payload.channel_name]: {
					...state[action.payload.channel_name],
					new_messages: action.payload.reset
						? initialState[action.payload.channel_name].new_messages
						: {
								count: state[action.payload.channel_name].new_messages.count + 1,
								...(action.payload.mentioned
									? { mentioned: action.payload.mentioned }
									: { mentioned: state[action.payload.channel_name].new_messages.mentioned }),
						  },
				},
			};

		/**New actions */
		case SUBSCRIBE_CHANNEL:
			return {
				...state,
				channels: {
					...state.channels,
					[action.payload.channel]: {
						...channelInitialState,
						participants: action.payload.participants,
						created: action.payload.created,
						ref_type: action.payload.ref_type,
						ref_id: action.payload.ref_id,
						title: action.payload.title,
						unread_count: parseInt(action.payload.unread_count),
					},
				},
				subscribed_channels: [...state.subscribed_channels.filter((channel) => channel !== action.payload.channel), action.payload.channel],
				subscribing_channels: state.subscribing_channels.filter((name) => name !== action.payload.channel),
			};
		case PARTICIPANT_SUBSCRIBED:
			return {
				...state,
				channels: {
					...state.channels,
					[action.payload.channel]: {
						...state.channels[action.payload.channel],
						participants: state.channels[action.payload.channel].participants.map((participant) =>
							participant.id === action.payload.sender.id ? { ...participant, online: 1 } : participant
						),
					},
				},
			};
		case PARTICIPANT_UNSUBSCRIBED:
			return {
				...state,
				channels: {
					...state.channels,
					[action.payload.channel]: {
						...state.channels[action.payload.channel],
						participants: state.channels[action.payload.channel].participants.map((participant) =>
							participant.id === action.payload.sender.id ? { ...participant, online: 0 } : participant
						),
					},
				},
			};
		case UNSUBSCRIBE_CHANNEL:
			if (state.channels && Object.keys(state.channels).length > 0) {
				const { [action.payload.channel]: all, ...rest } = state.channels;

				return {
					...state,
					channels: rest,
					subscribed_channels: state.subscribed_channels.filter((channel) => channel !== action.payload.channel),
				};
			} else {
				return { ...state };
			}
		case PUBLISH:
			let messages = state.channels[action.payload.channel].messages.items;
			if (action.payload.link_type == 'readmark') {
				messages = messages.map((message) => {
					if (
						message.unread == '1' &&
						message.origin_type === action.payload.origin_type &&
						message.origin_id == action.payload.origin_id
					) {
						if ((!action.payload.ref_id || action.payload.ref_id == '0') && (!action.payload.ref_type || action.payload.ref_type == '')) {
							message.unread = '0';
							return message;
						}
						if (message.ref_type === action.payload.ref_type) {
							if (!action.payload.ref_id || action.payload.ref_id == '0') {
								message.unread = '0';
								return message;
							}
							if (action.payload.ref_id && message.ref_id == action.payload.ref_id) {
								message.unread = '0';
								return message;
							}
						}
					} else if (message.unread == '1' && message.ref_type === action.payload.ref_type && typeof message.origin_type === 'undefined') {
						message.unread = '0';
					} else if (message.unread == '1' && action.payload?.ref_type == '' && typeof message.origin_type === 'undefined') {
						message.unread = '0';
					}
					return message;
				});
			}
			return {
				...state,
				...(state.channels && state.channels[action.payload.channel]
					? {
							channels: {
								...state.channels,
								[action.payload.channel]: {
									...state.channels[action.payload.channel],
									messages: {
										...state.channels[action.payload.channel].messages,
										items: [
											...messages,
											{
												...action.payload,
												text: action.payload.text,
												created: action.payload.created,
												sender: action.payload.sender,
												notify: action.payload.notify,
											},
										],
									},
								},
							},
					  }
					: {}),
			};
		case MODIFY:
			return {
				...state,
				...(state.channels && state.channels[action.payload.channel]
					? {
							channels: {
								...state.channels,
								[action.payload.channel]: {
									...state.channels[action.payload.channel],
									messages: {
										...state.channels[action.payload.channel].messages,
										items: [
											...state.channels[action.payload.channel].messages.map((message) => {
												if (message.id == action.payload.id) {
													return { ...message, text: action.payload.text, modified: 1, link_type: null, link: null };
												} else {
													return message;
												}
											}),
										],
									},
								},
							},
					  }
					: {}),
			};
		case SET_WS_CHANNEL_HISTORY:
			return {
				...state,
				[action.payload.channel]: {
					messages: {
						...state.channels[action.payload.channel].messages,
						items: action.payload.history,
					},
					participants: action.payload.participants,
				},
			};
		case SET_CHANNEL_CALLBACK:
			return {
				...state,
				[action.payload.channel]: {
					...state[action.payload.channel],
					callback: action.payload.callback,
				},
			};
		case DISCONNECT:
			return {
				...initialState,
				...(action.payload.keepToken && {
					token: state.token,
					token_expires: state.token_expires,
					subscribed_channels: state.subscribed_channels,
					available_channels: state.available_channels,
				}),
			};
		case SET_CONNECTION_TOKEN:
			return {
				...state,
				token: action.payload.token,
				token_expires: action.payload.token_expires,
			};
		case SET_CHANNELS:
			return {
				...state,
				available_channels: action.payload,
			};
		case GET_CHANNEL_MESSAGES:
			let items = [];
			let prev = null;
			let next = null;
			let rows = {
				after: {
					count: null,
					unread: null,
				},
				before: {
					count: null,
					unread: null,
				},
			};

			switch (state.channels[action.payload.channel].getting_messages) {
				case 'before':
					items = [...action.payload.messages.items, ...state.channels[action.payload.channel].messages.items];
					prev = action.payload.messages.prev;
					next = state.channels[action.payload.channel].next ?? null;
					rows = {
						before: action.payload.messages.rows.before,
						after: state.channels[action.payload.channel].messages.rows.after,
					};
					break;
				case 'after':
					items = [...action.payload.messages.items, ...state.channels[action.payload.channel].messages.items];
					prev = state.channels[action.payload.channel].prev ?? null;
					next = action.payload.messages.next;
					rows = {
						before: state.channels[action.payload.channel].messages.rows.before,
						after: action.payload.messages.rows.after,
					};
					break;
				case 'replace':
					items = [...action.payload.messages.items];
					prev = action.payload.messages.prev;
					next = action.payload.messages.next;
					rows = {
						before: action.payload.messages.rows.before,
						after: action.payload.messages.rows.after,
					};
					break;
				default:
					items = [...state.channels[action.payload.channel].messages.items];
					prev = state.channels[action.payload.channel].messages.prev ?? null;
					next = state.channels[action.payload.channel].messages.next ?? null;
					rows = { ...state.channels[action.payload.channel].messages.rows };
					break;
			}

			return {
				...state,
				channels: {
					...state.channels,
					[action.payload.channel]: {
						...state.channels[action.payload.channel],
						started: new Date(),
						messages: {
							...state.channels[action.payload.channel].messages,
							...action.payload.messages,
							// TODO: check rest of the props when loading more messages
							items,
							prev,
							next,
							rows,
						},
						getting_messages: false,
					},
				},
			};
		case SET_SUBSCRIBING_CHANNEL:
			return {
				...state,
				subscribing_channels: [...state.subscribing_channels, action.payload.channel],
			};
		case GETTING_CHANNEL_MESSAGES:
			return {
				...state,
				channels: {
					...state.channels,
					[action.payload.channel]: {
						...state.channels[action.payload.channel],
						getting_messages: action.payload.position,
					},
				},
			};
		case SET_UNREAD_COUNT:
			return {
				...state,
				channels: {
					...state.channels,
					[action.payload.channel]: {
						...state.channels[action.payload.channel],
						unread_count:
							action.payload.operation === 'add'
								? Math.max(0, parseInt(state.channels[action.payload.channel].unread_count) + 1)
								: Math.max(0, parseInt(state.channels[action.payload.channel].unread_count) - 1),
					},
				},
			};
		default:
			return state;
	}
}
