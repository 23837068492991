import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { TextField, Grid, Link, MenuItem, Container, Collapse, Typography, Button } from '@mui/material';
import { loginAction, handleSelectClientAction, resetLoginMfa, loginVerifyTOTPAction } from './actions/loginActions';
import Form from '../Generic/Form/Main';
import { push } from 'connected-react-router';
import Password from '../Users/MyPages/ChangePassword/Password';
import { TYPES as FORM_TYPES } from '../Generic/FormTypes';
import Countdown from 'react-countdown';

class LoginForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			username: '',
			password: '',
			selectedLanguage: this.props.selectedLanguage,
			recovery: this.props.recovery ? this.props.recovery : false,
			recovery_token: this.props.recovery_token ?? null,
			recovery_medium: this.props.recovery_medium ?? null,
			recovery_value: this.props.recovery_value ?? null,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.recovery !== this.props.recovery) {
			this.setState({
				recovery: this.props.recovery,
				recovery_token: this.props.recovery_token,
				recovery_medium: this.props.recovery_medium,
				recovery_value: this.props.recovery_value,
			});
		}
	}

	handleUsername = (e) => {
		this.setState({
			username: e.target.value,
		});
	};
	handlePassword = (e) => {
		this.setState({
			password: e.target.value,
		});
	};

	handleRecovery = () => {
		this.setState({
			recovery: !this.state.recovery,
			recovery_token: null,
			recovery_medium: null,
			recovery_value: null,
		});

		this.props.push('/login' + this.props.location.search);
	};

	handleConfig = () => {
		const { translate } = this.props;

		const structure = {
			form: 'login',
			title: '',
			subtitle: '',
			helperText: '',
			columns: [
				{
					id: 'column-1-login',
					text: '',
					grid_layout: { xs: 12 },
					separators: [
						{
							id: 'separator-1-1-login',
							text: '',
							collapse: false,
							divider: false,
							fields: [
								{
									id: 'login_email',
									name: 'login_email',
									label: translate('login_form_field_email'),
									type: FORM_TYPES.INPUT,
									props: {
										maxLength: 64,
									},
									validate: {
										required: true,
									},
								},
								{
									id: 'login_password',
									name: 'login_password',
									label: translate('login_form_field_password'),
									type: FORM_TYPES.PASSWORD,
									validate: {
										required: true,
									},
								},
								{
									id: 'deviceid',
									name: 'deviceid',
									label: '',
									type: FORM_TYPES.HIDDEN,
								},
								{
									id: 'language',
									name: 'language',
									label: '',
									type: FORM_TYPES.HIDDEN,
									initialvalue: this.props.selectedLanguage,
								},
							],
						},
					],
				},
			],
			buttons: [
				{
					id: 'password_login_submit_button',
					fullWidth: true,
					label: translate('generic_button_sign_in'),
					grid_layout: { xs: 12 },
					action: () => this.handleSubmitButton('login'),
					api_validation: true,
					disabled: this.props.login.client && this.props.login.client.id === 0,
				},
			],
		};

		return structure;
	};

	handleConfigMfa = () => {
		const { translate } = this.props;

		const structure = {
			form: 'login_mfa',
			title: '',
			subtitle: '',
			helperText: '',
			columns: [
				{
					id: 'column-1-mfa',
					text: '',
					grid_layout: { xs: 12 },
					separators: [
						{
							id: 'separator-1-1-mfa',
							text: '',
							collapse: false,
							divider: false,
							fields: [
								{
									id: 'totp',
									name: 'totp',
									label: translate('login_form_field_mfa_code'),
									type: FORM_TYPES.NUMBER,
									validate: {
										required: true,
									},
									disableNumberArrows: true,
								},
								{
									id: 'deviceid',
									name: 'deviceid',
									label: '',
									type: FORM_TYPES.HIDDEN,
								},
							],
						},
					],
				},
			],
			buttons: [
				{
					id: '2fa_login_submit_button',
					fullWidth: true,
					label: translate('generic_button_submit_mfa_code'),
					grid_layout: { xs: 12 },
					action: () => this.props.loginVerifyTOTPAction('login_mfa'),
					api_validation: true,
					disabled: this.props.login.client && this.props.login.client.id === 0,
				},
				{
					id: '2fa_resend_submit_button',
					fullWidth: true,
					submit: false,
					color: 'secondary',
					label: translate('login_button_mfa_resend_code'),
					grid_layout: { xs: 12 },
					action: () => this.props.resetLoginMfa(),
				},
			],
		};

		return structure;
	};

	handleSubmitButton = (form) => {
		if (typeof this.props.handleSubmitAction !== 'undefined') {
			this.props.handleSubmitAction(form);
		} else {
			this.props.loginAction(form);
		}
	};

	render() {
		const { translate } = this.props;
		const configuration = this.handleConfig();
		const configurationMfa = this.handleConfigMfa();
		return (
			<React.Fragment>
				{this.props.login.platform === 'backoffice' ? (
					<Container component="main" maxWidth="sm" className="generic-form-main-no-margin">
						<TextField
							fullWidth={true}
							select
							label={this.props.translate('login_select_client_backoffice')}
							value={this.props.login.client !== null && this.props.login.client.id}
							onChange={(value) =>
								this.props.handleSelectClientAction(
									this.props.settings.clients.find((client) => client.id === value.target.value),
									'backoffice'
								)
							}
							margin="normal"
							variant="outlined"
						>
							{this.props.settings.clients.map((client) => (
								<MenuItem key={client.id} value={client.id}>
									{client.name}
								</MenuItem>
							))}
						</TextField>
					</Container>
				) : null}
				<Collapse in={!this.state.recovery}>
					{this.props.settings.feature.self_registration &&
						this.props.login.platform == 'frontoffice' &&
						!this.props.disableSelfRegistration && (
							<Container component="main" maxWidth="sm" className="">
								<Typography align="justify" variant="subtitle2" component="p">
									{translate('user_create_new_account_info')}
								</Typography>
								<Button
									fullWidth
									color={'primary'}
									className="login-register-content-button"
									onClick={() => {
										this.props.push('/register/new' + this.props.router.location.search);
									}}
								>
									{translate('user_register_new_patient')}
								</Button>
							</Container>
						)}
					{this.props.settings.feature.self_registration &&
						this.props.login.platform == 'frontoffice' &&
						!this.props.login?.mfa?.require && (
							<Container component="main" maxWidth="sm">
								<Typography align="justify" variant="subtitle2">
									{translate('user_login_info')}
								</Typography>
							</Container>
						)}
					{this.props.login.hasOwnProperty('mfa') && this.props.login.mfa.require && (
						<Container component="main" maxWidth="sm">
							<Typography align="justify" variant="subtitle2">
								{translate('user_login_mfa_info', {
									counter: (
										<Countdown
											renderer={({ hours, minutes, seconds, completed }) => (
												<span>
													{hours !== 0 ? hours + ':' : ''}
													{minutes}:{seconds}
												</span>
											)}
											daysInHours={true}
											date={Date.now() + 60000}
										/>
									),
								})}
							</Typography>
						</Container>
					)}
					<Collapse in={this.props.login.hasOwnProperty('mfa') && this.props.login.mfa.require}>
						<Form form={configurationMfa.form} formData={configurationMfa} />
					</Collapse>
					<Collapse in={!this.props.login?.mfa.require}>
						<Form form={configuration.form} formData={configuration} />
					</Collapse>
				</Collapse>
				<Collapse in={this.state.recovery}>
					<Password
						hiddeTitles={true}
						handleRecovery={this.handleRecovery}
						recovery_token={this.state.recovery_token}
						recovery_medium={this.state.recovery_medium}
						recovery_value={this.state.recovery_value}
					/>
				</Collapse>
				{this.props.type == 'login' && (
					<Grid container>
						<Grid item xs={12} md={12}>
							<hr className="generic-hr-text" data-content={translate('login_bankid_or_separator')} />
						</Grid>
						<Grid item xs={12} md={12}>
							<Link
								href="javascript:void(0)"
								variant="body2"
								onClick={() => {
									this.props.handleSelectClientAction(null, null);
									this.props.push('/login');
								}}
								aria-label={translate('login_form_back_to_client_selection')}
							>
								&crarr; {translate('login_form_back_to_client_selection')}
							</Link>
						</Grid>
						<Grid item xs={12} md={12}>
							<Link
								href="javascript:void(0)"
								variant="body2"
								onClick={() => {
									this.handleRecovery();
								}}
								aria-label={this.state.recovery ? translate('login_go_back') : translate('login_password_recovery')}
							>
								&crarr; {this.state.recovery ? translate('login_go_back') : translate('login_password_recovery')}
							</Link>
						</Grid>
					</Grid>
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	login: state.login,
	settings: state.settings,
	router: state.router,
	location: state.router.location,
});

export default connect(mapStateToProps, { push, loginAction, handleSelectClientAction, resetLoginMfa, loginVerifyTOTPAction })(LoginForm);
