import {
	GET_MEETING,
  GET_MEETING_MESSAGES,
	GET_MEETING_ACCESS,
	MEETING_ERROR,
	GET_MEETING_PUBLIC_ACCESS,
	GET_MEETING_FIELDS,
	GET_MEETING_FILES_FIELDS,
} from '../actions/types';
import { CLEAR_REDUCER } from '../../Login/actions/types';

const initialState = {
	token: null,
	status: null,
	login_type: null,
	public_token: null,
	error_message: '',
	data: {},
  messages: {},
	fields: [],
	invitees_list: [],
	files: [],
	fields_files: [],
	invitee: {},
	scopes: []
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_MEETING:
			return {
				...state,
				data: action.payload.data,
				invitees_list: action.payload.invitees_list,
			};
    case GET_MEETING_MESSAGES:

      console.log("Reducer");
      console.log(action.payload);
      console.log(action.payload.data);
			return {
				...state,
				messages: action.payload.data,
			};
		case GET_MEETING_ACCESS:
			return {
				...state,
				...action.payload,
			};
		case CLEAR_REDUCER:
			return {
				...initialState,
			};
		case MEETING_ERROR:
			return {
				...state,
				...action.payload,
			};
		case GET_MEETING_PUBLIC_ACCESS:
			return {
				...state,
				public_token: action.payload,
			};
		case GET_MEETING_FIELDS:
			return {
				...state,
				fields: action.columns,
			};
		case GET_MEETING_FILES_FIELDS:
			return {
				...state,
				fields_files: action.files_columns,
			};
		default:
			return state;
	}
}
