import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import MUIDataTable from 'mui-datatables';
import tableActions from './actions/tableActions';
import { CircularProgress } from '@mui/material';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import MuiTablePagination from '@mui/material/TablePagination';

/* API config prop docs:
	Each object in the array represents a column of data for the datatable
		- column:   (string)    represents the name of the field where to fetch the data.
		- label:    (string)    column header (tag to be translated)
		- object:   (string)    name of the object if the column value comes from an child object
		- function: (function)  function from which values go throw and returns column value as string
		- values:   (array)     array of field names as to be passed into function
		- chips:    (object)    object with all possible cases from column value and return value to be displayed 
		- options:  (object)    accepts all column options from MUIDataTable package
		- display:  (string)    how the column should be displayed
			| hidden:           the values its there but hidden
			| text:             simple text field
			| object_field:     simple text from a child object (object property need to be set)
			| function:         string value return by function passed on function property
			| object_function:  string value return by function passed on function property with values from child object
			| chip:             object (JSX) displayed from conditional rendering depending on chips property object and field value
			| object_chip:      object (JSX) displayed from conditional rendering depending on chips property object and field value from child object
*/

const WAIT_INTERVAL = 750;

class Table extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tableStatePersist: {
				//Dynamic collection of props that are needed between table refreshes.
				searchText: '',
				filterList: [],
				columns: [],
			},
			loading: true,
			data: [],
			filters: {},
		};

		this.keyDownTimer = null;
	}

	handleTableData = (propsData, config) => {
		var data = [];
		var dataRow = {};
		let params = {};

		if (propsData && propsData !== null && propsData.length > 0) {
			propsData.forEach((obj) => {
				config.forEach((field) => {
					switch (field.display) {
						case 'text':
							dataRow[field.label] = typeof obj[field.column] !== 'undefined' && obj[field.column] !== null ? obj[field.column] : '-';
							break;

						case 'hidden':
							dataRow[field.label] = typeof obj[field.column] !== 'undefined' && obj[field.column] !== null ? obj[field.column] : '-';
							break;

						case 'hidden_object_field':
							dataRow[field.label] =
								typeof obj[field.object][field.column] !== 'undefined' && obj[field.object][field.column] !== null
									? obj[field.object][field.column]
									: '-';
							break;

						case 'function':
							params = {};

							field.values.forEach((value) => (params[value] = obj[value]));

							dataRow[field.label] = field.function(params);
							break;

						case 'object_function':
							params = {};

							field.values.forEach((value) => (params[value] = obj[field.object][value]));

							dataRow[field.label] = typeof obj[field.object] !== 'undefined' ? field.function(params) : '-';
							break;

						case 'object_field':
							dataRow[field.label] =
								typeof obj[field.object][field.column] !== 'undefined'
									? obj[field.object][field.column] !== null
										? obj[field.object][field.column]
										: '-'
									: '-';
							break;

						default:
							break;
					}
				});

				data.push(dataRow);
				dataRow = {};
			});
		}

		return data;
	};

	// handleServerSide = (data) => {
	// 	this.setState({ loading: false, data: this.handleTableData(data, this.props.config) });
	// }

	customFooter = (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels, data, rows_per_page) => {
		return (
			<TableFooter>
				<TableRow>
					{this.props.customFooterElement && <TableCell>{this.props.customFooterElement}</TableCell>}
					<TableCell>
						<MuiTablePagination
							component="div"
							count={count}
							rowsPerPage={rowsPerPage}
							page={page}
							labelRowsPerPage={textLabels.rowsPerPage}
							labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${textLabels.displayRows} ${count}`}
							backIconButtonProps={{
								id: this.props.table_type + '-table-previous-page-button',
								disabled: page === 0,
								'aria-label': textLabels.previous,
							}}
							nextIconButtonProps={{
								id: this.props.table_type + '-table-next-page-button',
								disabled: (page === 0 && count <= rowsPerPage) || (page !== 0 && count <= rowsPerPage * (page + 1)),
								'aria-label': textLabels.next,
							}}
							rowsPerPageOptions={rows_per_page}
							onPageChange={(_, page) => {
								changePage(page);
							}}
							onRowsPerPageChange={(event) => changeRowsPerPage(event.target.value)}
						/>
					</TableCell>
				</TableRow>
			</TableFooter>
		);
	};

	handleSearch = (tableState) => {
		clearTimeout(this.keyDownTimer);

		this.setState((state) => ({
			tableStatePersist: {
				...state.tableStatePersist,
				searchText: tableState.searchText,
			},
			loading: true,
			data: [],
		}));

		this.keyDownTimer = setTimeout(() => this.triggerSearch(tableState), WAIT_INTERVAL);
	};

	triggerSearch = (tableState) => {
		let filters = {};

		for (var i = 0; i < tableState.filterList.length; i++) {
			if (tableState.filterList[i].length > 0) {
				if (typeof tableState.filterList[i][0] === 'object') {
					filters[tableState.columns[i].name] = tableState.filterList[i][0].props.value;
				} else {
					filters[tableState.columns[i].name] = tableState.filterList[i].join();
				}
			}
		}

		this.props.onTableChangeAction(
			{ offset: 0, limit: tableState.rowsPerPage },
			{ ...filters, ...{ search: this.state.tableStatePersist.searchText } }
		);
		this.props.changeTablePage(this.props.tableId, 0);
	};

	render() {
		const { translate } = this.props;
		const data = this.handleTableData(this.props.data, this.props.config);

		const options = {
			onFilterChange: (changedColumn, filterList) => {
				this.props.setTableFiltersLists(this.props.tableId, filterList);
			},
			onSearchChange: (searchText) => {
				this.props.setTableSearch(this.props.tableId, searchText);
			},
			...(this.props.serverSide && { serverSide: true }),
			...(this.props.serverSide && {
				onTableChange: (action, tableState) => {
					// if (JSON.stringify(tableState) !== JSON.stringify(this.props.core)) {
					// 	this.props.setTableCoreStateAction(this.props.tableId, tableState);
					// }
					let filters = {};

					if (action === 'resetFilters') {
						this.setState((state) => ({
							tableStatePersist: {
								...state.tableStatePersist,
								filterList: [],
							},
						}));

						this.props.setTableFilters(this.props.tableId, {});

						this.props.onTableChangeAction(
							{ offset: 0, limit: tableState.rowsPerPage, page: tableState.page },
							{ ...{ search: this.state.tableStatePersist.searchText } }
						);
						this.props.changeTablePage(this.props.tableId, 0);
					}

					if (action === 'filterChange') {
						for (var i = 0; i < tableState.filterList.length; i++) {
							if (tableState.filterList[i].length > 0) {
								if (typeof tableState.filterList[i][0] === 'object') {
									filters[tableState.columns[i].name] = tableState.filterList[i][0].props.value;
								} else {
									filters[tableState.columns[i].name] = tableState.filterList[i].join();
								}
							}
						}

						this.setState((state) => ({
							tableStatePersist: {
								...state.tableStatePersist,
								filterList: tableState.filterList,
							},
							// filters: filters,
						}));

						this.props.setTableFilters(this.props.tableId, filters);

						this.props.onTableChangeAction(
							{ offset: 0, limit: tableState.rowsPerPage, page: tableState.page },
							{ ...filters, ...{ search: this.state.tableStatePersist.searchText } }
						);
						this.props.changeTablePage(this.props.tableId, 0);
					}

					if (action === 'search') {
						this.handleSearch(tableState);
					}

					if (action === 'changePage') {
						for (var i = 0; i < tableState.filterList.length; i++) {
							if (tableState.filterList[i].length > 0) {
								if (typeof tableState.filterList[i][0] === 'object') {
									filters[tableState.columns[i].name] = tableState.filterList[i][0].props.value;
								} else {
									filters[tableState.columns[i].name] = tableState.filterList[i].join();
								}
							}
						}

						this.setState({
							loading: true,
							data: [],
						});

						this.props.onTableChangeAction(
							{ offset: tableState.page * tableState.rowsPerPage, limit: tableState.rowsPerPage, page: tableState.page },
							{ ...filters, ...{ search: this.state.tableStatePersist.searchText } }
						);
						this.props.changeTablePage(this.props.tableId, tableState.page);

						setTimeout(() => {
							this.setState({
								loading: false,
							});
						}, 3000);
					}

					if (action === 'changeRowsPerPage') {
						for (var i = 0; i < tableState.filterList.length; i++) {
							if (tableState.filterList[i].length > 0) {
								if (typeof tableState.filterList[i][0] === 'object') {
									filters[tableState.columns[i].name] = tableState.filterList[i][0].props.value;
								} else {
									filters[tableState.columns[i].name] = tableState.filterList[i].join();
								}
							}
						}

						this.setState({
							loading: true,
							data: [],
						});

						this.props.onTableChangeAction(
							{ offset: 0, limit: tableState.rowsPerPage, page: tableState.page },
							{ ...filters, ...{ search: this.state.tableStatePersist.searchText } }
						);
						this.props.changeTablePage(this.props.tableId, 0);

						setTimeout(() => {
							this.setState({
								loading: false,
							});
						}, 3000);
					}
				},
			}),
			...(this.props.serverSide && { page: this.props.table.page }),
			filter: this.props.disableFilters ? false : this.props.scopes.includes('practitioner') ? true : false,
			search: this.props.disableSearchs ? false : this.props.scopes.includes('practitioner') ? true : false,
			responsive: 'standard',
			elevation: 0,
			selectableRows: 'none',
			expandableRows: this.props.onRowExpand ? true : false,
			expandableRowsHeader: false,
			renderExpandableRow: (rowData, rowMeta) => (this.props.onRowExpand ? this.props.onRowExpand(rowMeta, data, rowData) : false),
			expandableRowsOnClick: false,
			...(this.props.triggerRowExpand
				? {
						onRowExpansionChange: (currentRowsExpanded, allRowsExpanded, rowsExpanded) =>
							this.props.triggerRowExpand ? this.props.triggerRowExpand(allRowsExpanded, data, rowsExpanded) : false,
				  }
				: {
						onRowExpansionChange: (currentRowsExpanded, allRowsExpanded, rowsExpanded) => {
							let elementIndex = currentRowsExpanded[0].index;
							let elementBottomIndex = elementIndex + 1;

							let tableElement = document.getElementById(this.props.tableId);

							let element = tableElement.querySelector("tr[data-testid='MUIDataTableBodyRow-" + elementIndex + "']");
							let elementBottom = tableElement.querySelector("tr[data-testid='MUIDataTableBodyRow-" + elementBottomIndex + "']");

							if (typeof allRowsExpanded.find((row) => row.index == currentRowsExpanded[0].index) != 'undefined') {
								element.classList.add('muitable-expanded-top-row');
								if (elementBottom !== null) {
									elementBottom.classList.add('muitable-expanded-bottom-row');
								}
							} else {
								element.classList.remove('muitable-expanded-top-row');
								if (elementBottom !== null) {
									elementBottom.classList.remove('muitable-expanded-bottom-row');
								}
							}
						},
				  }),
			...(this.props.rowsExpanded && { rowsExpanded: this.props.rowsExpanded }),
			onRowClick: (rowData, rowMeta) => (this.props.onRowClick ? this.props.onRowClick(rowMeta, data) : false),
			onCellClick: (rowData, rowMeta) => (this.props.onCellClick ? this.props.onCellClick(rowMeta, data) : false),
			print: false,
			...(this.props.serverSide && { searchText: this.state.tableStatePersist.searchText }),
			// count: this.props.table.count,
			download: false,
			viewColumns: false,
			customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) =>
				this.props.disableFooter
					? null
					: this.customFooter(
							this.props.serverSide && this.props.table.count ? this.props.table.count : count,
							page,
							rowsPerPage,
							changeRowsPerPage,
							changePage,
							textLabels,
							data,
							this.props.rows_per_page
					  ),
			textLabels: {
				body: {
					noMatch: this.props.table.loading ? translate('table_body_loading') : translate('table_body_no_match'),
					toolTip: translate('table_body_tooltip'),
					columnHeaderTooltip: (column) => `Sort for ${column.label}`,
				},
				pagination: {
					next: translate('table_pagination_previous'),
					previous: translate('table_pagination_previous'),
					rowsPerPage: translate('table_pagination_rows_per_page'),
					displayRows: translate('table_pagination_display_rows'),
				},
				toolbar: {
					search: translate('table_toolbar_search'),
					downloadCsv: translate('table_toolbar_download_csv'),
					print: translate('table_toolbar_print'),
					viewColumns: translate('table_toolbar_view_columns'),
					filterTable: translate('table_toolbar_filter_table'),
				},
				filter: {
					all: translate('table_filter_all'),
					title: translate('table_filter_title'),
					reset: translate('table_filter_reset'),
				},
				viewColumns: {
					title: translate('table_view_columns_title'),
					titleAria: translate('table_view_columns_title_aria'),
				},
				selectedRows: {
					text: translate('table_selected_rows_text'),
					delete: translate('table_selected_rows_delete'),
					deleteAria: translate('table_selected_rows_delete_aria'),
				},
			},
			...(this.props.customToolbar && {
				customToolbar: (displayData) => this.props.customToolbar(displayData),
			}),
			...(this.props.setRowProps && {
				setRowProps: (row, dataIndex, rowIndex) => this.props.setRowProps(row, dataIndex, rowIndex),
			}),
			customFilters: this.props.filters,
			onChangeRowsPerPage: (numberOfRows) => {
				this.props.setTableRowsPerPage(this.props.tableId, numberOfRows);
			},
			...(this.props.table.rows_per_page && {
				rowsPerPage: this.props.table.rows_per_page ? this.props.table.rows_per_page : 10,
			}),
			searchText: this.props.table.search,
			searchAlwaysOpen: this.props.searchAlwaysOpen ? true : false,
		};

		const columns = this.props.columns.map((column, index) => ({
			...column,
			options: { ...column.options, filterList: this.props.table.filter_list ? this.props.table.filter_list[index] : null },
		}));

		return (
			<div id={this.props.tableId}>
				<MUIDataTable
					ref={(ref) => {
						if (typeof this.props.setRef !== 'undefined') {
							this.props.setRef(ref);
						}
					}}
					title={this.props.table.loading ? <CircularProgress size={24} style={{ position: 'relative', top: 4 }} /> : ''}
					data={data}
					columns={columns}
					options={options}
					// tableState={this.props.core}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	let tables_setting = state.settings?.site?.tables;

	return {
		translate: getTranslate(state.localize),
		scopes: state.users.whoami.scopes,
		table: state.table[ownProps.tableId],
		rows_per_page:
			typeof tables_setting !== 'undefined' && tables_setting[ownProps.tableId]?.rows_per_page
				? tables_setting[ownProps.tableId]?.rows_per_page
				: [10],
		core: state.table[ownProps.tableId].core,
	};
};

export default connect(mapStateToProps, {
	changeTablePage: tableActions.changeTablePageAction,
	setTableFilters: tableActions.setTableFiltersAction,
	setTableCoreState: tableActions.setTableCoreStateAction,
	setTableRowsPerPage: tableActions.setTableRowsPerPageAction,
	setTableFiltersLists: tableActions.setTableFiltersListsAction,
	setTableSearch: tableActions.setTableSearchAction,
})(Table);
